import type { MouseEvent } from 'react'
import type { CalendarClickType, MonthProps as Props } from '../typings'

import { useCallback } from 'react'

import { cardSize, cardTopSpace, monthSpace, monthWidth } from '../constants'

import { useMonthStyle } from './useMonthStyle'

import { Stack } from '@mui/material'
import { Day } from './Day'
import { DaysOfWeek } from './DaysOfWeek'
import { EmptyDay } from './EmptyDay'

type DayDataset = {
  day: string
  resetSelection?: string
}

const rowHeight = cardSize + cardTopSpace
const sixWeeksCalendarHeight = rowHeight * 6

const defaultDotColor = '$pigmentGreen'

export function Month(props: Props) {
  const {
    days,
    onClick,
    index,
    showDaysOfWeek,
    onMouseOverDay,
    allowAutoMonthHeight,
    dotColor = defaultDotColor,
  } = props

  const classes = useMonthStyle({
    height: 'auto',
    width: 'auto',
    marginLeft: index > 0 ? monthSpace : 0,
  })

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.target && !(e.target as HTMLElement).matches('button.o-calendar__card')) {
        return
      }
      const dataset = (e.target as HTMLButtonElement).dataset as DayDataset
      const { day, resetSelection = '0' } = dataset

      if (!day) {
        if (process.env.NODE_ENV === 'development') {
          throw new Error('Invalid day dataset')
        }
        return
      }

      const type: CalendarClickType = e.type === 'dblclick' ? 'dblclick' : 'click'
      const targetDay = resetSelection === '1' ? undefined : day
      onClick(targetDay, type)
    },
    [onClick],
  )

  const minHeight = allowAutoMonthHeight ? 'auto' : sixWeeksCalendarHeight

  return (
    <div className={classes.root} onClick={handleOnClick} onDoubleClick={handleOnClick}>
      {showDaysOfWeek && <DaysOfWeek actualDays={days} />}

      <Stack
        direction="row"
        flexWrap="wrap"
        width={monthWidth}
        style={{
          minHeight,
          alignContent: 'flex-start',
        }}
      >
        {days.map((day, index) =>
          day.type === 'empty' ? (
            <EmptyDay firstOfRow={day.firstOfRow} key={index} />
          ) : (
            <Day {...day} onMouseOver={onMouseOverDay} dotColor={dotColor} key={day.id} />
          ),
        )}
      </Stack>
    </div>
  )
}
