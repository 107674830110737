import { Separator } from '@/components/Separator'
import { useSendMessageToDriverModal } from '@/components/modals/SendMessageToDriverModal'
import { useInspectPin } from '@/map'

import { useInspectPinNavigoActions } from '../hooks/useInspectPinNavigoActions'
import { useIsGpsOnly } from '../hooks/useIsGpsOnly'
import { usePermissions } from '../hooks/usePermissions'
import { useStyles } from '../hooks/useStyles'
import { showByPermission } from '../utils'

import { Stack } from '@mui/material'

import { CreateGeofenceButton } from './CreateGeofenceButton'
import { CreateOrderButton } from './CreateOrderButton'
import { DispatchToDriverButton } from './DispatchToDriverButton'
import { SavePlaceButton } from './SavePlaceButton'

export function Actions() {
  const classes = useStyles()
  const [inspectPin] = useInspectPin()
  const { createOrder, createGeofence, createPlace } = useInspectPinNavigoActions()
  const { show: showSendMessageToDriver, Modal: SendMessageToDriverModal } =
    useSendMessageToDriverModal()
  const canCreateOrder = !useIsGpsOnly()
  const permissions = usePermissions()

  if (!inspectPin) return null

  const validInspectPin = inspectPin?.status === 'ready'

  return (
    <Stack direction="row" height="100%" width="auto" className={classes.actionsWrapper}>
      {canCreateOrder && <CreateOrderButton onClick={createOrder} disabled={!validInspectPin} />}

      {showByPermission(permissions.createGeofence) && (
        <CreateGeofenceButton onClick={createGeofence} disabled={!validInspectPin} />
      )}

      {showByPermission(permissions.dispatchToDriver) && (
        <DispatchToDriverButton onClick={showSendMessageToDriver} disabled={!validInspectPin} />
      )}

      {showByPermission(permissions.savePlace) && (
        <>
          <Separator />
          <SavePlaceButton disabled={!validInspectPin} onClick={createPlace} />
        </>
      )}

      <SendMessageToDriverModal locationToShare={inspectPin.latLng} />
    </Stack>
  )
}
