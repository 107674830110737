import type { ActionPermissions } from '../../../../hooks/useDevicesNavigoMachine/types'

import { Separator } from '@/components/Separator'

import { useFitMapOnBreadcrumbFetch } from '../../../../../../hooks/useFitMapOnBreadcrumbFetch'

import { BreadcrumbsButton } from './components/BreadcrumbsButton'
import { CenterOnMapButton } from './components/CenterOnMapButton'
import { EditButton } from './components/EditButton'
import { EventsButton } from './components/EventsButton'
import { ExpandButton } from './components/ExpandButton'
import { ReportButton } from './components/ReportButton'
import { SendMessageButton } from './components/SendMessageButton'

import { useActions } from './hooks/useActions'
import { useBreadcrumbsStatus } from './hooks/useBreadcrumbsStatus'
import { useEvents } from './hooks/useEvents'
import { useModalActions } from './hooks/useModalActions'
import { useStyles } from './hooks/useStyles'

import { Stack } from '@mui/material'

import { disabledByPermission, showByPermission } from './utils'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
  permissions: ActionPermissions
  devices: uui.domain.client.gps.wwgps.DeviceInfo[]
}

export function Actions(props: Props) {
  const { expanded, permissions, devices, toggleExpanded } = props

  const breadcrumbStatus = useBreadcrumbsStatus(devices)
  const { modals, modalActions } = useModalActions()
  const eventsCount = useEvents(devices)

  const { toggleBreadcrumbs, centerOnMap, goToEdit, goToEvents, runReports } = useActions(
    devices,
    breadcrumbStatus,
    eventsCount,
  )
  const classes = useStyles()
  useFitMapOnBreadcrumbFetch(devices)

  return (
    <Stack
      direction="row"
      data-trackid="navigo-device-overview-actions"
      data-testid="navigo-device-overview-actions"
      alignItems="center"
      width="auto"
      height="100%"
    >
      <Stack
        direction="row"
        height="100%"
        width="auto"
        className={classes.actionsWrapper}
        alignItems="center"
      >
        {expanded && (
          <>
            {showByPermission(permissions.events) && (
              <EventsButton
                disabled={disabledByPermission(permissions.events)}
                onClick={goToEvents}
                count={eventsCount}
              />
            )}

            {showByPermission(permissions.breadcrumbs) && (
              <BreadcrumbsButton
                disabled={disabledByPermission(permissions.breadcrumbs)}
                onClick={toggleBreadcrumbs}
                status={breadcrumbStatus}
              />
            )}

            <Separator className={classes.separator} />

            {showByPermission(permissions.report) && (
              <ReportButton
                onClick={runReports}
                disabled={disabledByPermission(permissions.report)}
              />
            )}

            {showByPermission(permissions.sendMessage) && (
              <SendMessageButton
                onClick={modalActions.showSendMessageToDriverModal}
                disabled={disabledByPermission(permissions.sendMessage)}
              />
            )}

            <CenterOnMapButton onClick={centerOnMap} permission={permissions.centerOnMap} />
          </>
        )}

        <ExpandButton expanded={expanded} onClick={toggleExpanded} />

        {showByPermission(permissions.edit) && (
          <EditButton onClick={goToEdit} disabled={disabledByPermission(permissions.edit)} />
        )}
      </Stack>

      {modals}
    </Stack>
  )
}
