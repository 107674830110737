import { Tooltip } from '@/components/primitives/Tooltip'
import { ArrowDownward, ArrowUpward, RemoveCircleOutline } from '@mui/icons-material'
import { Box, IconButton, Stack, TextField } from '@mui/material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import type { FormErrors, FormFields } from '../formFields'
import { useActions } from './useActions'
import { useTexts } from './useTexts'

type Props = {
  value: string
  index: number
  errorMessage: string | null
}

export function ReasonField(props: Props) {
  const texts = useTexts()
  const { value, index, errorMessage } = props
  const actions = useActions(index)
  const {
    field: { value: reasons },
    formApi,
  } = useFormField<'reasonsForNotCompletedOrders', FormFields, FormErrors>(
    'reasonsForNotCompletedOrders',
  )

  const moveUpTooltip =
    reasons.length === 1 ? texts.oneReason : index === 0 ? texts.firstReason : texts.moveUp

  const moveDownTooltip =
    reasons.length === 1
      ? texts.oneReason
      : index === reasons.length - 1
        ? texts.lastReason
        : texts.moveDown

  const { submitting, disabled } = formApi.getMeta()
  const formDisabled = submitting || disabled

  const firstReason = index === reasons.length - 1
  const lastReason = index === 0

  const arrowUpDisabled = firstReason || formDisabled
  const arrowDownDisabled = lastReason || formDisabled

  const removeColor = formDisabled ? 'disabled' : 'error'

  return (
    <Stack direction="row" alignItems="start" height="76px" overflow="hidden">
      <Tooltip title={moveDownTooltip} placement="top">
        <IconButton
          onClick={actions.moveReasonDown}
          disabled={arrowUpDisabled}
          data-testid="reason-for-orders_move-down-btn"
          data-trackid="reason-for-orders_move-down-btn"
        >
          <ArrowDownward />
        </IconButton>
      </Tooltip>
      <Tooltip title={moveUpTooltip} placement="top">
        <IconButton
          onClick={actions.moveReasonUp}
          disabled={arrowDownDisabled}
          data-testid="reason-for-orders_move-up-btn"
          data-trackid="reason-for-orders_move-up-btn"
        >
          <ArrowUpward />
        </IconButton>
      </Tooltip>
      <Box marginTop="0" flex="1">
        <TextField
          placeholder={texts.placeholder}
          value={value}
          onChange={actions.editReason}
          helperText={errorMessage}
          error={!!errorMessage}
          margin="none"
          disabled={formDisabled}
          data-testid="reason-for-orders_reason-txt"
        />
      </Box>
      <Tooltip title={texts.remove} placement="top">
        <Box marginLeft={1}>
          <IconButton
            onClick={actions.removeReason}
            disabled={formDisabled}
            data-testid="reason-for-orders_remove-reason-btn"
          >
            <RemoveCircleOutline color={removeColor} />
          </IconButton>
        </Box>
      </Tooltip>
    </Stack>
  )
}
