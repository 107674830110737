import type { CSSProperties } from 'react'
import type { ActionPermissions } from '../../hooks/useVehiclesNavigoMachine/types'

import { useSelector } from 'react-redux'

import { Separator } from '@/components/Separator'
import { selectCalendarRange } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'

import { CloseButton } from '../../../../elements/CloseButton'
import { useVehiclesNavigator } from '../../hooks/useVehiclesNavigator'

import { Actions } from './components/Actions'
import { Details } from './components/Details'
import { VehicleInfo } from './components/VehicleInfo'

import { RoutesButton } from './components/RoutesButton'
import { StylerButton } from './components/StylerButton'

import { Stack } from '@mui/material'

interface Props {
  expanded: boolean
  goToStyle: () => void
  toggleExpanded: () => void
  permissions: ActionPermissions
}

const growStyle: CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  height: 46,
  gap: 16,
}

export function Overview(props: Props) {
  const { expanded, permissions, toggleExpanded, goToStyle } = props

  const { currentVehicles, currentVehicleIds } = useVehiclesNavigator()
  const calendarRange = useSelector(selectCalendarRange)
  const userConfig = useSelector(selectUserConfiguration)

  if (currentVehicles.length === 0) return null

  const isToday =
    calendarRange.minDate === calendarRange.maxDate && calendarRange.minDate === userConfig.today

  return (
    <>
      <Stack width="100%" style={growStyle}>
        <Details vehicles={currentVehicles} />
        {currentVehicles.length === 1 && isToday && (
          <>
            <Separator />
            <VehicleInfo vehicle={currentVehicles[0]} />
          </>
        )}
      </Stack>
      <Actions
        toggleExpanded={toggleExpanded}
        vehicles={currentVehicles}
        permissions={permissions}
        expanded={expanded}
      />
      <StylerButton onClick={goToStyle} permission={permissions.style} vehicles={currentVehicles} />
      <RoutesButton vehicleIds={currentVehicleIds} />
      <CloseButton />
    </>
  )
}
