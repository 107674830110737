import { useEffect } from 'react'

import { Dropdown } from '@/components/Dropdown'
import { WarningTriangle } from '@/icons'
import { H4, WarningCard } from '@/local/components'

import { Stack } from '@mui/material'

import { useController } from '../hooks/useController'
import { useControllerActions } from '../hooks/useControllerActions'
import { useDateOptions } from '../hooks/useDateOptions'
import { useStepOptions } from '../hooks/useStepOptions'
import { useVehicleOptions } from '../hooks/useVehicleOptions'
import { useWarningCardTexts } from '../hooks/useWarningCardTexts'
import { useTexts } from '../useTexts'

const labelSpace = 8
const dropdownMaxHeight = 120
const dropdownBlockSpace = 22

const warningCardStyle = { marginTop: dropdownBlockSpace }

export function Body() {
  const texts = useTexts()
  const dateOptions = useDateOptions()
  const stepOptions = useStepOptions()
  const vehicleOptions = useVehicleOptions()
  const actions = useControllerActions()
  const {
    updateData,
    status,
    data: { isForceFitting, areDateAndVehicleSet, orderSteps },
  } = useController()

  const warningCardTexts = useWarningCardTexts(orderSteps.length > 1, isForceFitting)

  // If there is no selected step, select the first one not disabled
  useEffect(() => {
    const selectedStep = stepOptions.find(o => o.selected)

    if (selectedStep) return

    const option = stepOptions.find(s => !s.disabled)
    updateData({ activeStep: option?.routeStep })
  }, [updateData, stepOptions])

  return (
    <Stack direction="column" height="100%" width="100%" flexShrink={0}>
      <Stack
        direction="column"
        height="100%"
        width="100%"
        sx={{ marginTop: `${dropdownBlockSpace}px` }}
      >
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.date}
        </H4>
        <Dropdown
          disabled={status === 'submitting'}
          onChange={actions.onChangeDateOption}
          maxListHeight={dropdownMaxHeight}
          options={dateOptions}
          testId="date-dropdown"
        />
      </Stack>

      <Stack
        direction="column"
        height="100%"
        width="100%"
        sx={{ marginTop: `${dropdownBlockSpace}px` }}
      >
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.vehicle}
        </H4>
        <Dropdown
          onChange={actions.onChangeVehicleOption}
          disabled={status === 'submitting'}
          maxListHeight={dropdownMaxHeight}
          options={vehicleOptions}
          testId="vehicle-dropdown"
        />
      </Stack>

      <Stack
        direction="column"
        height="100%"
        width="100%"
        sx={{ marginTop: `${dropdownBlockSpace}px` }}
      >
        <H4 color="$paleSky" marginBottom={labelSpace} uppercase>
          {texts.insertAfter}
        </H4>
        <Dropdown
          options={stepOptions}
          disabled={status === 'submitting' || !areDateAndVehicleSet}
          maxListHeight={dropdownMaxHeight}
          onChange={actions.onChangeStepOption}
          testId="step-dropdown"
        />
      </Stack>

      <WarningCard
        preset={isForceFitting ? 'warning' : 'info'}
        description={warningCardTexts.description}
        style={warningCardStyle}
        Icon={
          <WarningTriangle
            size={16}
            color="$pureWhite"
            background={isForceFitting ? '$darkOrange' : '$scienceBlue'}
          />
        }
      />
    </Stack>
  )
}
