import { VirtualList } from '@workwave-tidal/tidal/components'
import { useMemo } from 'react'

import { useController } from '../../../../hooks/useController'
import { useControllerActions } from '../../../../hooks/useControllerActions'
import { DeviceListItem } from './components/DeviceListItem'
import { DriverListItem } from './components/DriverListItem'

import { Stack } from '@mui/material'

const getDeviceItemHeights = () => 24
const getDriverItemHeights = () => 39

export function ChooseRecipientBody() {
  const {
    data: { gpsOnly, devices, drivers, selectedId },
  } = useController()
  const { updateSelectedDriver, updateSelectedDevice } = useControllerActions()
  const selectedIds = useMemo(() => (selectedId ? [selectedId] : undefined), [selectedId])

  return (
    <Stack direction="column" width="100%" height="100%" flexShrink={0} marginTop={4}>
      {gpsOnly ? (
        <VirtualList
          listHeight={getDeviceItemHeights() * 6}
          getItemHeights={getDeviceItemHeights}
          items={devices}
          selectedItemIds={selectedIds}
          RenderItem={DeviceListItem}
          onSelect={updateSelectedDevice}
        />
      ) : (
        <VirtualList
          listHeight={getDriverItemHeights() * 6}
          getItemHeights={getDriverItemHeights}
          items={drivers}
          selectedItemIds={selectedIds}
          RenderItem={DriverListItem}
          onSelect={updateSelectedDriver}
        />
      )}
    </Stack>
  )
}
