import type { ActionPermissions } from '../../../../hooks/useDriversNavigoMachine/types'

import { Stack } from '@mui/material'

import { EditButton } from './components/EditButton'
import { ExpandButton } from './components/ExpandButton'
import { SendMessageButton } from './components/SendMessageButton'
import { VehicleAssignmentButton } from './components/VehicleAssignmentButton'

import { useActions } from './hooks/useActions'
import { useModalActions } from './hooks/useModalActions'
import { useStyles } from './hooks/useStyles'
import { disabledByPermission } from './utils'

interface Props {
  drivers: uui.domain.client.rm.ExtendedDriver[]
  expanded: boolean
  toggleExpanded: () => void
  permissions: ActionPermissions
}

export function Actions(props: Props) {
  const { expanded, permissions, toggleExpanded, drivers } = props

  const { modals, modalActions } = useModalActions()
  const actions = useActions(drivers)
  const classes = useStyles()

  return (
    <Stack
      direction="row"
      data-trackid="navigo-drivers-actions"
      data-testid="navigo-drivers-actions"
      width="auto"
      alignItems="center"
      height="100%"
    >
      <Stack direction="row" width="auto" className={classes.actionsWrapper} alignItems="center">
        {expanded && (
          <>
            <SendMessageButton
              onClick={modalActions.showSendMessageToDriverModal}
              disabled={disabledByPermission(permissions.sendMessage)}
            />

            <VehicleAssignmentButton
              onClick={actions.vehicleAssignment}
              disabled={disabledByPermission(permissions.vehicleAssignment)}
            />
          </>
        )}

        <ExpandButton expanded={expanded} onClick={toggleExpanded} />

        <EditButton onClick={actions.edit} />
      </Stack>

      {modals}
    </Stack>
  )
}
