import type { ModalState } from '@/atoms'
import { ReactElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import { Backdrop, Box, Dialog, Stack } from '@mui/material'

import { useCloseModal, useCurrentModal } from '@/atoms'
import { DraggableLayer } from '@/components/DraggableLayer'

import { ModalBody } from './elements/ModalBody'
import { useStyles } from './hooks/useStyles'

type Props = {
  scrollbars?: boolean
  header?: ReactElement
  footer?: ReactElement
  config?: Partial<ModalState['config']>
  children?: ReactNode
  adornment?: ReactNode
  minWidth?: number
  maxWidth?: number
  testid?: string
}

export function ModalContent(props: Props) {
  const {
    children,
    header,
    footer,
    config: customConfig,
    maxWidth,
    minWidth,
    testid = 'modal',
    adornment,
  } = props

  const ref = useRef<null | HTMLDivElement>(null)

  const closeModal = useCloseModal()
  const { config } = useCurrentModal()

  const { maxHeight, disableEscapeKeyDown, disableBackdropClick } = useMemo(() => {
    return {
      ...config,
      ...(customConfig ?? {}),
    }
  }, [config, customConfig])

  const [innerHeight, setInnerHeight] = useState<number>(Number.MAX_SAFE_INTEGER)

  const scrollbarsHeight = useMemo(() => {
    return Math.ceil(Math.min(Math.abs(maxHeight), innerHeight))
  }, [maxHeight, innerHeight])

  const onClose = useCallback(
    (_event, reason) => {
      if (reason === 'backdropClick' && disableBackdropClick) return

      closeModal()
    },
    [closeModal, disableBackdropClick],
  )

  const [classes, styles] = useStyles({ maxWidth, minWidth })

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth={false}
      BackdropComponent={Backdrop}
      disableEscapeKeyDown={disableEscapeKeyDown}
      transitionDuration={600}
      PaperProps={{
        ref,
      }}
    >
      <Box data-testid={testid} className={classes.modalContainer} style={styles.modalContainer}>
        <DraggableLayer
          draggable
          container={ref.current}
          render={(onMouseDown, dragging) => {
            const style = {
              width: '100%',
              cursor: dragging ? 'grabbing' : 'grab',
            }

            return (
              <Stack direction="row" width="100%" height="100%">
                <Stack direction="column" height="100%" width="100%" flexShrink={1}>
                  {!!header && (
                    <div onMouseDown={onMouseDown} style={style}>
                      {header}
                    </div>
                  )}

                  <Box width="100%" height="100%" flex="0 1 auto">
                    <ModalBody
                      height={scrollbarsHeight}
                      onUpdateHeight={setInnerHeight}
                      scrollBars={props.scrollbars}
                    >
                      {children}
                    </ModalBody>
                  </Box>

                  {!!footer && footer}
                </Stack>
                {adornment}
              </Stack>
            )
          }}
        />
      </Box>
    </Dialog>
  )
}
