import type { ReactNode } from 'react'

import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'

interface Props {
  children?: ReactNode
}

const useStyles = makeStyles({
  container: {
    '& > * + *': {
      marginLeft: 8,
    },
  },
})

export function ButtonsContainer(props: Props) {
  const classes = useStyles()
  return (
    <Stack direction="row" height="100%" width="auto" className={classes.container}>
      {props.children}
    </Stack>
  )
}
