import type { ActionPermissions } from '../../../../hooks/useVehiclesNavigoMachine/types'

import { Separator } from '@/components/Separator'

import { BreadcrumbsButton } from './components/BreadcrumbsButton'
import { CenterOnMapButton } from './components/CenterOnMapButton'
import { EditButton } from './components/EditButton'
import { EventsButton } from './components/EventsButton'
import { ExpandButton } from './components/ExpandButton'
import { ReportButton } from './components/ReportButton'
import { SendMessageButton } from './components/SendMessageButton'

import { useActions } from './hooks/useActions'
import { useBreadcrumbsStatus } from './hooks/useBreadcrumbsStatus'
import { useEvents } from './hooks/useEvents'
import { useFitMapOnVehiclesForBreadcrumbFetch } from './hooks/useFitMapOnVehiclesForBreadcrumbFetch'
import { useModalActions } from './hooks/useModalActions'
import { useStyles } from './hooks/useStyles'

import { Stack } from '@mui/material'
import { showByPermission } from './utils'

interface Props {
  expanded: boolean
  toggleExpanded: () => void
  permissions: ActionPermissions
  vehicles: uui.domain.client.UnifiedVehicle[]
}

export function Actions(props: Props) {
  const { expanded, permissions, vehicles, toggleExpanded } = props

  const breadcrumbStatus = useBreadcrumbsStatus(vehicles)
  const { modals, modalActions } = useModalActions()
  const eventsCount = useEvents(vehicles)
  const { toggleBreadcrumbs, goToEvents, centerOnMap, goToEdit, runReports } = useActions(
    vehicles,
    breadcrumbStatus,
    eventsCount,
  )

  const classes = useStyles()
  useFitMapOnVehiclesForBreadcrumbFetch(vehicles)

  return (
    <Stack
      direction="row"
      data-trackid="navigo-vehicle-overview-actions"
      data-testid="navigo-vehicle-overview-actions"
      alignItems="center"
      width="auto"
      height="100%"
    >
      <Stack
        direction="row"
        height="100%"
        width="auto"
        className={classes.actionsWrapper}
        alignItems="center"
      >
        {expanded && (
          <>
            {showByPermission(permissions.events) && (
              <EventsButton
                permission={permissions.events}
                vehicles={vehicles}
                onClick={goToEvents}
                count={eventsCount}
              />
            )}

            {showByPermission(permissions.breadcrumbs) && (
              <BreadcrumbsButton
                permission={permissions.breadcrumbs}
                onClick={toggleBreadcrumbs}
                status={breadcrumbStatus}
                vehicles={vehicles}
              />
            )}

            <Separator className={classes.separator} />

            {showByPermission(permissions.report) && (
              <ReportButton
                permission={permissions.report}
                vehicles={vehicles}
                onClick={runReports}
              />
            )}

            {showByPermission(permissions.sendMessage) && (
              <SendMessageButton
                onClick={modalActions.showSendMessageToDriverModal}
                permission={permissions.sendMessage}
                vehicles={vehicles}
              />
            )}

            <CenterOnMapButton
              permission={permissions.centerOnMap}
              vehicles={vehicles}
              onClick={centerOnMap}
            />
          </>
        )}

        <ExpandButton expanded={expanded} onClick={toggleExpanded} />

        <EditButton onClick={goToEdit} permission={permissions.edit} />
      </Stack>

      {modals}
    </Stack>
  )
}
