import { useCallback, useMemo } from 'react'

import { setMainSelection } from '@/atoms'
import { ViolationTag } from '@/components/Navigo/components/ViolationTag'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { useTexts } from '../useTexts'

interface ViolationsData {
  orderWithViolationsIds: string[]
  violations: uui.domain.rm.RouteStepViolationsType[]
}
interface Props {
  extOrders: uui.domain.client.rm.ExtendedOrderStep[]
}

export function Bulk(props: Props) {
  const { extOrders } = props
  const texts = useTexts()

  const { orderWithViolationsIds, violations } = useMemo(
    () =>
      extOrders.reduce<ViolationsData>(
        (acc, extOrder) => {
          if (!extOrder.isUnassigned && !!extOrder?.violations) {
            acc.orderWithViolationsIds.push(extOrder.id)
            acc.violations.push(...extOrder.violations)
          }
          return acc
        },
        { orderWithViolationsIds: [], violations: [] },
      ),
    [extOrders],
  )
  const onClick = useCallback(() => {
    setMainSelection('orderSteps', orderWithViolationsIds)
  }, [orderWithViolationsIds])

  return (
    <Stack
      direction="column"
      height="100%"
      data-testid="navigo-orders-detail"
      justifyContent="space-between"
      width="100%"
      flexShrink={0}
    >
      <Text size="$l" weight="$semiBold">
        {texts.orders(extOrders.length)}
      </Text>

      {violations.length > 0 ? (
        <ViolationTag collapsed={false} violations={violations} onClick={onClick} noTooltip />
      ) : (
        <Text size="$p3">{texts.noViolations}</Text>
      )}
    </Stack>
  )
}
