import { getMatchingRecurrenceByDate } from '@/local/server-data/utils/recurrences'
import { TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID } from '../../constants'
import * as vehicleHelpers from './vehicle'

export const UnifiedIdPlaceHolders = {
  separator: '@@',
  device: '[DEVICE-ID]',
  vehicle: '[VEHICLE-ID]',
}

export const getUnifiedId = (deviceId?: string, vehicleId?: string) => {
  const gpsId = typeof deviceId === 'string' ? deviceId.toString() : UnifiedIdPlaceHolders.device

  const rmId = vehicleId && vehicleId.trim().length > 0 ? vehicleId : UnifiedIdPlaceHolders.vehicle

  return `${gpsId}${UnifiedIdPlaceHolders.separator}${rmId}`
}

export const getGpsTagsFromVehicle = (
  vehicle?: uui.domain.client.rm.Vehicle,
): uui.domain.client.gps.wwgps.Tag[] => {
  if (!vehicle) {
    return []
  }

  const vehicleSettings = vehicleHelpers.getCalendarizedSettings(vehicle)

  return vehicleSettings.tags.map<uui.domain.client.gps.wwgps.Tag>((value, id) => ({
    id: id.toString(),
    value,
    name: 'tag',
  }))
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------
// UnifiedVehicles instance method converted to static helpers

export const getDevice = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  recurrence: string = 'any',
) => {
  if (uv.allowGps && uv.isPhysicalDevice) {
    return uv.device
  }

  if (uv.hasRoutingLicense) {
    const matchingRecurrence = getMatchingRecurrenceByDate(
      recurrence,
      Object.keys(uv.driversByRecurrence),
    )

    const driverAndDeviceId = uv.driversByRecurrence[matchingRecurrence]

    if (!driverAndDeviceId.associated) return

    const driverId = driverAndDeviceId?.driverId
    const extendedDriver = drivers[driverId]

    return extendedDriver?.type === 'linked' ? extendedDriver.device : undefined
  }
}

export const getDeviceId = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  recurrence: string = 'any',
) => {
  const device = getDevice(drivers, uv, recurrence)

  if (device) {
    return device.deviceId
  }

  // If the device is not found, it should be untracked, but since on Telematics mobile the devices
  // are created at the moment of the commit of the transaction, we try to check for the presence of a GPS device id
  if (
    trackingProvider === 'telematics' &&
    uv.hasRoutingLicense &&
    uv.vehicle.gpsDeviceId === TELEMATICS_DUMMY_GPS_MOBILE_DEVICE_ID
  ) {
    const matchingRecurrence = getMatchingRecurrenceByDate(
      recurrence,
      Object.keys(uv.driversByRecurrence),
    )

    const driverAndDeviceId = uv.driversByRecurrence[matchingRecurrence]

    if (!driverAndDeviceId.associated) return

    const driverId = driverAndDeviceId?.driverId
    const extendedDriver = drivers[driverId]

    return extendedDriver.driver.deployment.gpsDeviceId
  }
}

export const getLabel = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  recurrence: string = 'any',
) => {
  if (uv.hasRoutingLicense) {
    return uv.vehicle.externalId
  }

  const device = getDevice(drivers, uv, recurrence)

  if (!device) return ''

  return (device as uui.domain.client.gps.wwgps.DeviceInfo)?.label ?? ''
}

export const getPrimaryColor = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  trackingProvider: uui.domain.server.gps.TrackingProvider,
  recurrence: string = 'any',
) => {
  if (uv.hasRoutingLicense) {
    return uv.vehicle.color
  }

  const device = getDevice(drivers, uv, recurrence)

  if (!device) return '303030'

  if (trackingProvider !== 'WWGPS') {
    return '303030'
  }

  return (device as uui.domain.client.gps.wwgps.DeviceInfo)?.iconColor1 ?? 'FFF'
}

export const getSecondaryColor = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  recurrence: string = 'any',
) => {
  if (uv.hasRoutingLicense) {
    return uv.vehicle.color
  }

  const device = getDevice(drivers, uv, recurrence)

  if (!device) return 'FFF'

  return (device as uui.domain.client.gps.wwgps.DeviceInfo)?.iconColor1 ?? 'FFF'
}

export const getExtendedDriver = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  recurrence: string = 'any',
) => {
  if (uv.hasRoutingLicense) {
    const matchingRecurrence = getMatchingRecurrenceByDate(
      recurrence,
      Object.keys(uv.driversByRecurrence),
    )

    const driverAndDeviceId = uv.driversByRecurrence[matchingRecurrence]
    if (!driverAndDeviceId.associated) return

    const { driverId } = driverAndDeviceId

    return drivers[driverId]
  }
}

export const getDriver = (
  drivers: Record<string, uui.domain.client.rm.ExtendedDriver>,
  uv: uui.domain.client.UnifiedVehicle,
  recurrence: string = 'any',
) => {
  return getExtendedDriver(drivers, uv, recurrence)?.driver
}

export const hasTrackingLicense = (
  uv: uui.domain.client.UnifiedVehicle,
  recurrence: string = 'any',
) => {
  if (uv.allowGps && uv.isPhysicalDevice) {
    return !!uv.device
  }

  if (uv.hasRoutingLicense) {
    const driverAndDevice = uv.driversByRecurrence[recurrence]
    return driverAndDevice?.associated && driverAndDevice?.deviceId
  }

  return false
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

export const getRouteStatsStartingLoads = (
  steps: uui.domain.client.rm.RouteStep[],
  orders: Record<string, uui.domain.client.rm.Order>,
) => {
  return steps.reduce<Record<string, number>>((startingLoadsAcc, step) => {
    if (step.ref) {
      const order = orders[step.ref]

      if (!order) {
        throw new Error(`Missing order (${step.ref}) while computing starting loads for a route`)
      }

      const withoutPickup = order.type !== 'pd' && order.type !== 'p'
      const qualified = step.type !== 'pickup' && withoutPickup

      for (const [key, rawVal] of Object.entries(order.loads)) {
        // initialize all keys with zero
        startingLoadsAcc[key] = startingLoadsAcc[key] ?? 0

        if (qualified) {
          startingLoadsAcc[key] += rawVal / 100
        }
      }
    }

    return startingLoadsAcc
  }, {})
}

type MinMax = { max: number; min: number }
type LoadsKey = string
type MinMaxMap = Record<LoadsKey, MinMax>

export const getRouteStatsLoadCapacities = (
  ve: uui.domain.client.rm.Vehicle,
  recurrence: string = 'any',
) => {
  const capacities: MinMaxMap = {}

  const settings = vehicleHelpers.getCalendarizedSettings(ve, recurrence)
  const { loadCapacities, minLoadCapacities } = settings

  for (const [loadKey, value] of Object.entries(loadCapacities)) {
    capacities[loadKey] = {
      max: value,
      min: minLoadCapacities[loadKey] ?? 0,
    }
  }

  return capacities
}

export const getRouteStatsLoadsData = (
  steps: uui.domain.client.rm.RouteStep[],
  orders: Record<string, uui.domain.client.rm.Order>,
  loadCapacities: MinMaxMap,
  startLoads: Record<string, number>,
) => {
  // build a map of load data from vehicle load capacities
  const loadsData = Object.entries(loadCapacities).reduce<uui.domain.client.rm.RouteStats['loads']>(
    (loadsAcc, [key, { max }]) => {
      loadsAcc[key] = {
        capacity: max,
        max: startLoads[key] ?? 0,
        min: startLoads[key] ?? 0,
        saturation: 0,
        used: startLoads[key] ?? 0,
      }

      return loadsAcc
    },
    {},
  )

  // add additional loads not derived from vehicle capacities
  stepsLoop: for (const step of steps) {
    const { ref, type } = step
    const order = orders[ref ?? '#']

    if (!order) {
      // TODO: should it throw?
      continue stepsLoop
    }

    for (const [key, loadValue] of Object.entries(order.loads)) {
      const value = loadValue / 100
      const loadData = loadsData[key] ?? {
        capacity: 0,
        max: startLoads[key] ?? 0,
        min: startLoads[key] ?? 0,
        saturation: 0,
        used: startLoads[key] ?? 0,
      }

      const loadDir = type === 'pickup' ? 1 : -1
      const max = loadData.used > loadData.max ? loadData.used : loadData.max
      const min = loadData.used < loadData.min ? loadData.used : loadData.min
      const saturation = loadData.capacity ? max / loadData.capacity : 0

      loadData.saturation = saturation
      loadData.max = max
      loadData.min = min
      loadData.used = loadData.used + value * loadDir

      loadsData[key] = loadData
    }
  }

  return loadsData
}
