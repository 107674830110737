import { Tooltip } from '@/components/primitives/Tooltip'
import { Separator } from '@/components/Separator'
import { Edit, NewGeofence, Share } from '@/icons'

import { styled, Stack } from '@mui/material'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'

import { usePermissions } from '../../../../hooks/usePermissions'
import { showByPermission } from '../../../../utils'

import { useShareLocation } from './hooks/useShareLocation'
import { useActions } from './hooks/useActions'
import { useTexts } from './useTexts'

export const ActionsWrapper = styled(Stack, { name: 'ActionsWrapper' })({
  flexDirection: 'row',
  height: '100%',
  alignItems: 'center',
  '& > * + *': {
    marginLeft: '8px',
  },
})

export function ExpandedActions() {
  const { canBeShown, showSendMessageToDriver } = useShareLocation()
  const { currentDepots, actions } = useActions()
  const texts = useTexts()
  const permissions = usePermissions()

  if (currentDepots.length === 0) return null

  return (
    <ActionsWrapper width="auto" alignItems="center">
      {currentDepots.length === 1 && (
        <>
          {showByPermission(permissions.createGeofence) && (
            <ReadOnlyTooltip
              render={preventEditing => (
                <NavigoIconButton
                  text={texts.newGeofence}
                  Icon={
                    <NewGeofence
                      size={17}
                      background={preventEditing ? '$silver' : '$nightRider'}
                      foreground={preventEditing ? '$silver' : '$shadyLady'}
                    />
                  }
                  onClick={actions.goToNewGeofence}
                  disabled={preventEditing}
                />
              )}
            />
          )}

          {showByPermission(permissions.shareLocation) && (
            <Tooltip placement="top" title={canBeShown.reason ?? ''} disabled={canBeShown.result}>
              <NavigoIconButton
                disabled={!canBeShown.result}
                text={texts.shareLocation}
                onClick={showSendMessageToDriver}
                Icon={<Share size={17} color={canBeShown.result ? '$nightRider' : '$silver'} />}
              />
            </Tooltip>
          )}

          <Separator />
        </>
      )}

      <ReadOnlyTooltip
        render={preventEditing => (
          <NavigoIconButton
            onClick={actions.goToEdit}
            disabled={preventEditing}
            text={currentDepots.length === 1 ? texts.edit : texts.bulkEdit}
            Icon={<Edit size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
          />
        )}
      />
    </ActionsWrapper>
  )
}
