import type { ReactNode } from 'react'

import { Divider, Stack, styled } from '@mui/material'

import { theme } from '@/styles'

type Props = {
  width: number
  children?: ReactNode
  testid?: string
}

export function FormColumn(props: Props) {
  return (
    <Stack
      position="relative"
      direction="column"
      height="100%"
      flexShrink={0}
      width={props.width}
      alignItems="center"
      data-testid={props.testid}
    >
      {props.children}
      <Separator />
    </Stack>
  )
}

const Separator = styled(Divider, { name: 'Separator' })({
  position: 'absolute',
  top: 0,
  left: '100%',
  width: '1px',
  height: '100%',
  backgroundColor: theme.colors.$silver,
  zIndex: 4,
})
