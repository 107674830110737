import type { PopoverOrigin, PopoverPosition } from '@mui/material'
import { Popover, Stack } from '@mui/material'

import { useIsInPopup } from '@/atoms'
import { NoDispatch } from '@/icons'
import { Text } from '@/local/components'

import { OrderStatusBadge } from '../OrderStatusBadge'

import { useOpenWithDelay } from '../../hooks/useOpenWithDelay'
import { useOrdersStatus } from '../../hooks/useOrdersStatus'

import { useFixPopupPosition } from '../../hooks/useFixPopupPosition'
import { DriversDetail } from './components/DriversDetail'
import { RoutesDetail } from './components/RoutesDetail'
import { StatusDetail } from './components/StatusDetail'
import { useStyles } from './hooks/useStyles'
import { useTexts } from './useTexts'

interface Props {
  extOrderSteps: uui.domain.client.rm.ExtendedOrderStep[]
  delay?: number
  transformOrigin: PopoverOrigin
  anchorPosition: PopoverPosition
}

export function MultipleOrders(props: Props) {
  const { extOrderSteps, delay, ...popoverProps } = props

  const ordersStatus = useOrdersStatus(extOrderSteps)
  const classes = useStyles()
  const isOpen = useOpenWithDelay(delay)
  const texts = useTexts()

  const actionsRef = useFixPopupPosition()
  const isInPopup = useIsInPopup()

  const disablePortal = isInPopup || window.innerHeight == window.screen.height

  return (
    <Popover
      open={isOpen}
      action={actionsRef}
      className={classes.popover}
      // Avoid MUI moves the tooltip back to the main window through a portal
      disablePortal={disablePortal}
      anchorReference="anchorPosition"
      {...popoverProps}
    >
      <Stack
        direction="column"
        paddingTop={2}
        paddingBottom={2}
        paddingLeft={2}
        paddingRight={ordersStatus === 'notDispatched' ? 4 : 2}
        className={classes.container}
      >
        {ordersStatus === 'notDispatched' && (
          <NoDispatch className={classes.noDispatchIcon} size={10} />
        )}

        <DriversDetail extOrderSteps={extOrderSteps} />

        <Text size="$m" weight="$semiBold">
          {texts.orders(extOrderSteps.length)}
        </Text>

        {typeof ordersStatus === 'object' ? (
          <StatusDetail status={ordersStatus} />
        ) : (
          <OrderStatusBadge status={ordersStatus} />
        )}

        <RoutesDetail extOrders={extOrderSteps} />
      </Stack>
    </Popover>
  )
}
