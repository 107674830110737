import { useState, useCallback } from 'react'

import { Stack, styled } from '@mui/material'

import { IconButton } from '@/components/primitives/buttons'

import { Edit, DoubleArrow, Duplicate, Export, Unassign, Trash, Gears, CopyOrders } from '@/icons'
import { useNumberOfAddableOrders, useIsSimulation, useOrdersLimit } from '@/hooks'

import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'

import { NavigoIconButton } from '../../../../../../elements/NavigoIconButton'
import { useTexts } from '../../useTexts'

import { useBtnTexts } from './hooks/useBtnTexts'
import { useActions } from './hooks/useActions'
import { useCanUnassign } from './hooks/useCanUnassign'

const rotateIcon = { transform: 'rotate(-180deg)' }

export const ActionsWrapper = styled(Stack, { name: 'ActionsWrapper' })({
  flexDirection: 'row',
  height: '100%',
  width: 'auto',
  alignItems: 'center',
  '& > * + *': {
    marginLeft: '8px',
  },
})

export function Actions() {
  const [expanded, setExpanded] = useState(true)

  const { currentOrders, assignedOrders, unassignedOrders, actions, modals } = useActions()
  const btnTexts = useBtnTexts(currentOrders.length > 1)
  const addableOrders = useNumberOfAddableOrders()
  const isSimulation = useIsSimulation()
  const ordersLimit = useOrdersLimit()
  const texts = useTexts()
  const canUnassign = useCanUnassign()

  const toggleExpanded = useCallback(() => setExpanded(prev => !prev), [])

  // Texts
  let unassignText = ''

  switch (currentOrders.length) {
    // Unassign
    case 1:
      unassignText = texts.unassign
      break

    // Unassign all
    case assignedOrders.length:
      unassignText = texts.unassignAll
      break

    // Unassign (x)
    default:
      unassignText = `${texts.unassign} (${assignedOrders.length})`
  }

  let fitInText = ''
  switch (currentOrders.length) {
    // Fit In
    case 1:
      fitInText = texts.fitIn
      break

    // Fit In all
    case unassignedOrders.length:
      fitInText = texts.fitInAll
      break

    // Fit In (x)
    default:
      fitInText = `${texts.fitIn} (${unassignedOrders.length})`
  }

  // If no orders just return null
  if (currentOrders.length === 0) return null

  const canDuplicateOrders = addableOrders >= currentOrders.length

  const expandTestId = expanded
    ? 'orders-overview-actions-expand'
    : 'orders-overview-actions-collapse'

  return (
    <Stack
      direction="row"
      height="100%"
      width="auto"
      alignItems="center"
      data-trackid="navigo-order-overview-actions"
      data-testid="navigo-order-overview-actions"
    >
      {expanded && (
        <ActionsWrapper>
          {isSimulation && (
            <NavigoIconButton
              onClick={actions.showCopySelectedOrdersModal}
              text={texts.copyOrders(currentOrders.length)}
              Icon={<CopyOrders size={17} color="$nightRider" />}
              testid="navigo-order-copy-orders-button"
            />
          )}
          <ReadOnlyTooltip
            canEdit={canDuplicateOrders}
            title={texts.duplicateDisabledTooltipTitle(
              addableOrders,
              currentOrders.length,
              isSimulation,
            )}
            subtitle={texts.duplicateDisabledTooltipSubtitle(
              addableOrders,
              currentOrders.length,
              isSimulation,
              ordersLimit,
            )}
            render={preventEditing => (
              <NavigoIconButton
                disabled={preventEditing}
                text={btnTexts.duplicate}
                Icon={<Duplicate size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
                onClick={actions.showDuplicateOrderModal}
                testid="navigo-order-duplicate-button"
              />
            )}
          />
          <NavigoIconButton
            text={btnTexts.export}
            Icon={<Export size={17} color="$nightRider" />}
            onClick={actions.showExportOrderModal}
            testid="navigo-order-export-button"
          />
          {/* Should be shown when there's at least one assigned order */}
          {assignedOrders.length > 0 && (
            <ReadOnlyTooltip
              canEdit={canUnassign}
              title={texts.disabledForLockedRouteTooltipTitle}
              render={preventEditing => (
                <NavigoIconButton
                  Icon={<Unassign size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
                  onClick={actions.showUnassignOrderModal}
                  disabled={preventEditing}
                  text={unassignText}
                  testid="navigo-order-unassign-button"
                />
              )}
            />
          )}
          {/* Should be shown when not all the orders are assigned */}
          {unassignedOrders.length > 0 && (
            <ReadOnlyTooltip
              render={preventEditing => (
                <NavigoIconButton
                  text={fitInText}
                  disabled={preventEditing}
                  Icon={<Gears size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
                  onClick={actions.showFitInOrderModal}
                  testid="navigo-order-fit-in-button"
                />
              )}
            />
          )}
          <ReadOnlyTooltip
            render={preventEditing => (
              <NavigoIconButton
                text={btnTexts.delete}
                disabled={preventEditing}
                Icon={<Trash size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
                onClick={actions.showDeleteOrderModal}
                testid="navigo-order-delete-button"
              />
            )}
          />
          <ReadOnlyTooltip
            render={preventEditing => (
              <NavigoIconButton
                text={btnTexts.edit}
                disabled={preventEditing}
                Icon={<Edit size={17} color={preventEditing ? '$silver' : '$nightRider'} />}
                onClick={actions.goToEdit}
                testid="navigo-order-edit-button"
              />
            )}
          />
        </ActionsWrapper>
      )}

      <IconButton onClick={toggleExpanded} data-testid={expandTestId} data-trackid={expandTestId}>
        <DoubleArrow size={10} color="$nightRider" style={!expanded ? rotateIcon : undefined} />
      </IconButton>
      {modals}
    </Stack>
  )
}
