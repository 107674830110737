import type { Props } from './typings'

import { useCallback, useEffect, useState } from 'react'
import { Stack, Typography, Divider, IconButton, Box } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

import { getOperatingSystem } from '@/utils'
import { ZoomRoutes } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'

import { schedulerInstance } from '../../../../atoms/project/project'

import { SearchOrdersBar } from './components/SearchOrdersBar'
import { ContextualMenu } from './components/ContextualMenu'
import { RoutesCounter } from './components/RoutesCounter'

import { SchedulerHeaderWrapper } from './elements/SchedulerHeaderWrapper'
import RightActions from './elements/RightActions'
import LeftActions from './elements/LeftActions'

import { useTexts } from './useTexts'

const MIN_ZOOM = 0
const MAX_ZOOM = 19

export function SchedulerHeader(props: Props) {
  const {
    groupBy,
    unitMode,
    routeStats,
    setGroupBy,
    setAllExpanded,
    setUnitMode,
    allSelected,
    allExpanded,
    editColumns,
    ResizeHandle,
    onZoomRoutes,
    routesCounter,
    selectedRoutes,
    toggleSelectAll,
    toggleExpandCollapseAll,
    showApprovedRoutes,
    approvedRoutesVisible,
    setShowUnavailableRoutes,
    areUnavailableRoutesVisible,
    removeUnavailableRoutesFromSelection,
  } = props

  const texts = useTexts()

  const { routeIds, unavailableRouteIds } = routeStats
  const os = getOperatingSystem()

  const [schedulerZoom, setSchedulerZoom] = useState(
    () => schedulerInstance.instance?.zoomLevel ?? MIN_ZOOM,
  )

  const onZoomOutClick = useCallback(() => {
    if (!schedulerInstance.instance) return

    schedulerInstance.instance.zoomToLevel(schedulerZoom - 1)
  }, [schedulerZoom])

  const onZoomInClick = useCallback(() => {
    if (!schedulerInstance.instance) return
    schedulerInstance.instance.zoomToLevel(schedulerZoom + 1)
  }, [schedulerZoom])

  useEffect(() => {
    setTimeout(() => {
      if (!schedulerInstance.instance) return
      setSchedulerZoom(schedulerInstance.instance.zoomLevel ?? MIN_ZOOM)
      schedulerInstance.instance.onPresetChange = params => {
        setSchedulerZoom(params.source.zoomLevel)
      }
    }, 0)
  }, [])

  const dividerStyles = {
    height: '30.75px',
  }

  return (
    <SchedulerHeaderWrapper data-testid="Scheduler-header">
      {ResizeHandle}

      <LeftActions>
        <Typography fontWeight="bold" fontSize={20}>
          {texts.routes}
        </Typography>

        <RoutesCounter
          allSelected={allSelected}
          routesCounter={routesCounter}
          toggleSelectAll={toggleSelectAll}
        />

        <ContextualMenu
          removeUnavailableRoutesFromSelection={removeUnavailableRoutesFromSelection}
          areUnavailableRoutesVisible={areUnavailableRoutesVisible}
          setShowUnavailableRoutes={setShowUnavailableRoutes}
          unavailableRoutes={unavailableRouteIds.length}
          approvedRoutesVisible={approvedRoutesVisible}
          setShowApprovedRoutes={showApprovedRoutes}
          toggleSelectAll={toggleSelectAll}
          toggleExpandCollapseAll={toggleExpandCollapseAll}
          numberOfRoutes={routeIds.length}
          selectedRoutes={selectedRoutes}
          routesCounter={routesCounter}
          editColumns={editColumns}
          setUnitMode={setUnitMode}
          allSelected={allSelected}
          allExpanded={allExpanded}
          setGroupBy={setGroupBy}
          setAllExpanded={setAllExpanded}
          unitMode={unitMode}
          groupBy={groupBy}
        />
      </LeftActions>

      <RightActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack gap={1} direction="row">
            <Tooltip
              placement="top"
              content={
                <Stack direction="row" spacing={2}>
                  <Typography color="white">{texts.zoomOutTooltipCommand}</Typography>
                  <Typography color="rgba(255,255,255,0.5)">
                    {os === 'MacOS'
                      ? texts.zoomOutTooltipKeysMacOs
                      : texts.zoomOutTooltipKeysOthers}
                  </Typography>
                </Stack>
              }
            >
              <IconButton
                onClick={onZoomOutClick}
                disabled={schedulerZoom === MIN_ZOOM}
                data-testid="scheduler-zoom-out-btn"
                data-trackid="scheduler-zoom-out-btn"
              >
                <Remove htmlColor="rgba(0,0,0,.56)" />
              </IconButton>
            </Tooltip>

            <Tooltip placement="top" title={texts.zoomRoutesCommand}>
              <IconButton
                onClick={onZoomRoutes}
                data-testid="scheduler-zoom-routes-btn"
                data-trackid="scheduler-zoom-routes-btn"
              >
                <ZoomRoutes size={24} style={{ padding: '1px', color: 'rgba(0, 0, 0, 0.56)' }} />
              </IconButton>
            </Tooltip>

            <Tooltip
              placement="top"
              content={
                <Stack direction="row" spacing={2}>
                  <Typography color="white">{texts.zoomInTooltipCommand}</Typography>
                  <Typography color="rgba(255,255,255,0.5)">
                    {os === 'MacOS' ? texts.zoomInTooltipKeysMacOs : texts.zoomInTooltipKeysOthers}
                  </Typography>
                </Stack>
              }
            >
              <IconButton
                onClick={onZoomInClick}
                disabled={schedulerZoom === MAX_ZOOM}
                data-testid="scheduler-zoom-in-btn"
                data-trackid="scheduler-zoom-in-btn"
              >
                <Add htmlColor="rgba(0,0,0,.56)" />
              </IconButton>
            </Tooltip>
          </Stack>

          <Box display="flex" alignItems="center">
            <Divider orientation="vertical" sx={dividerStyles} flexItem />
          </Box>

          <SearchOrdersBar />
        </Stack>
      </RightActions>
    </SchedulerHeaderWrapper>
  )
}
