import { makeStyles } from '@mui/styles'

import { Pin } from '@/icons'
import { Text } from '@/local/components'

import { Stack } from '@mui/material'

import { useTexts } from '../useTexts'

const useStyles = makeStyles({
  container: {
    maxWidth: 260,
  },
})

export function Info() {
  const classes = useStyles()
  const texts = useTexts()

  return (
    <Stack
      direction="row"
      height="100%"
      width="100%"
      alignItems="center"
      className={classes.container}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        sx={{ marginLeft: '20px', marginRight: '30px', width: '50px' }}
        flexShrink={0}
      >
        <Pin size={20} color="$nightRider" />

        <Text size="$s" color="$nightRider" textAlign="center">
          {texts.inspect}
        </Text>
      </Stack>

      <Stack direction="column" flexShrink={0} width="100%" height="100%" justifyContent="center">
        <Text size="$m" color="$nightRider" weight="$semiBold" textAlign="left">
          {texts.mapInspectorTool}
        </Text>

        <Text size="$m" color="$nightRider" textAlign="left">
          {texts.inspectPointOnMap}
        </Text>
      </Stack>
    </Stack>
  )
}
